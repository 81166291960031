<template>
    <span>
        <b-form-group
            :id="`form-group-${name}`"
            :description="description"
            :label-for="`form-input-${name}`"
            label-size="sm"
        >
            <template v-slot:label>
                {{ title }}
                <template v-if="!!tooltip">
                    <b-icon :id="`show-tooltip-${name}`" class="bootstrap-icon" icon="question-circle-fill" />
                    <Tooltip :target="`show-tooltip-${name}`" :title="tooltip" />
                </template>
            </template>
            <b-form-radio-group
                :stacked="stacked"
                v-model="selected"
                :options="options"
                :state="fieldState"
                :autocomplete="autocomplete"
            >
                <b-form-invalid-feedback v-if="fieldState === false" :state="fieldState">
                    {{ errorLabel }}
                </b-form-invalid-feedback>
            </b-form-radio-group>
        </b-form-group>
    </span>
</template>

<script>
import Tooltip from '@/components/Common/Tooltip';

export default {
    name: 'RadioButton',
    components: { Tooltip },
    props: {
        stacked: {
            type: Boolean,
            required: false,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: true,
            validator: prop =>
                prop.length > 0 &&
                prop.every(
                    p => typeof p.text === 'string' && (typeof p.value === 'string' || typeof p.value === 'number')
                )
        },
        model: {
            type: [String, Number],
            required: true,
            default: ''
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        autocomplete: {
            type: String,
            required: false
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        tooltip: {
            type: String,
            required: false,
            default: ''
        },
        returnObject: {
            type: Boolean,
            required: false,
            default: false
        },
        errorLabel: {
            type: String,
            required: false,
            default: 'This field is required.'
        },
        validator: {
            type: Function,
            required: false,
            default: () => true
        },
        showError: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        selected: ''
    }),
    computed: {
        isValid() {
            return !!this.validator(this.selected);
        },
        fieldState() {
            return !this.showError || this.isValid ? null : false;
        }
    },
    mounted() {
        this.selected = this.model;
    },
    watch: {
        selected(val) {
            if (this.returnObject) {
                val = this.options.find(option => option.value === val);
            }

            this.$emit('inputChanged', val);
            this.$emit('validate', this.isValid);
        },
        model(val) {
            this.selected = val;
        }
    }
};
</script>

<style lang="scss" scoped>
::v-deep .custom-control-label {
    cursor: pointer;

    span {
        // Between bullet and text is not covered by bootstrap's radio button component.
        // If a user clicks there, nothing will happen.
        // To prevent that, we pull the text close to the bullet and apply padding to push away visually
        // so the empty area can be clickable.
        margin-left: -7px;
        padding-left: 7px;
    }
}

::v-deep .custom-control-input:checked ~ .custom-control-label:after {
    border: 0;
    background-color: $pacific-blue;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    top: 0.15rem;
    left: -1.6rem;
}

::v-deep .custom-control-label:after {
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
}

::v-deep .custom-control-input:not(:checked):hover ~ .custom-control-label:after {
    background-color: grey;
    border-radius: 50%;
    transition: 0.5s background-color ease;
    cursor: pointer;
}

::v-deep .form-group {
    label:first-child {
        font-weight: 700;
    }
}
</style>
